@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Black-Trial.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Heavy-Trial.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Bold-Trial.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Medium-Trial.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Regular-Trial.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Light-Trial.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte Inktrap";
  src: url("./assets/fonts/ABCWhyteInktrap/ABCWhyteInktrap-Thin-Trial.otf") format("opentype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte";
  src: url("./assets/fonts/ABCWhyte/ABCWhyte-Regular-Trial.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "ABC Whyte";
  src: url("./assets/fonts/ABCWhyte/ABCWhyte-Light-Trial.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PPEiko";
  src: url("./assets/fonts/PPEiko//PPEiko-Thin.otf") format("opentype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "PPEiko";
  src: url("./assets/fonts/PPEiko//PPEiko-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PPEiko";
  src: url("./assets/fonts/PPEiko//PPEiko-Heavy.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
.App,
#root,
model-viewer {
  width: 100vw;
  height: 100vh;
  height: calc(var(--variable-vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
}

model-viewer {
  display: none;
}

h1 {
  font-family: "ABC Whyte Inktrap";
  font-weight: 600;
  font-size: 96px;
  line-height: 101px;
}
h2 {
  font-family: "ABC Whyte Inktrap";
  font-weight: 700;
  font-size: 56px;
  line-height: 62px;
}
h3 {
  font-family: "ABC Whyte Inktrap";
  font-weight: 300;
  font-size: 42px;
  line-height: 63px;
}
h4 {
  font-family: "ABC Whyte Inktrap";
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
}
h5 {
  font-family: "ABC Whyte Inktrap";
  font-weight: 500;
  font-size: 21px;
  line-height: 23px;
}
h6 {
  font-family: "ABC Whyte";
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
  margin: 0;
}
p {
  font-family: "ABC Whyte";
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
}
p.small {
  font-family: "ABC Whyte";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
}

button {
  min-width: 144px;
  height: 48px;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 70px;
  color: #3a3532;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
}

.container {
  outline: none !important;
}

.eiko {
  font-family: PPEiko !important;
}
